import React, { useMemo } from "react";
import { Field, Form } from "react-final-form";

import InputNumber from "components/UI/atoms/InputNumber";
import Select, { IOption } from "components/UI/atoms/Select";

import { COEFFICIENT_CLARIFICATION_OPTIONS, COEFFICIENT_STEP_OPTIONS } from "../../HandlerCoefficients";
import Dropdown from "shared/ui/atoms/Dropdown/Dropdown";
import Actions from "shared/ui/controls/Actions/Actions";
import Checkbox from "shared/ui/inputs/Checkbox/Checkbox";
import InputBase from "shared/ui/inputs/InputBase";

import { ReactComponent as ArrowDownSelect } from "images/icons/arrowDownSelect.svg";

import styles from "./HandlerCoefficientsForm.module.scss";

interface IProps {
  values?: any;
  onSubmit: (vals: any) => void;
  onCancel: () => void;
  isPending?: boolean;
  coefficientsForSteps: IOption[];
}

const HandlerCoefficientsForm: React.FC<IProps> = ({ values, onSubmit, onCancel, isPending, coefficientsForSteps }) => {
  const coefficientSteps = useMemo(() => COEFFICIENT_STEP_OPTIONS.concat(coefficientsForSteps), [coefficientsForSteps]);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={values}
      key={values}
      render={({ handleSubmit, form }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.form}>
            <div className={styles.row1}>
              <Field
                name="name"
                component={InputBase as any}
                placeholder="Название коэффициента*"
                required
                className={styles.name}
              />
              <Field
                name="coefficient_step"
                required
                render={({ input, meta }) => (
                  <Select
                    options={coefficientSteps}
                    placeholder="Применять к*"
                    input={input}
                    meta={meta}
                    containerClassName={styles.select}
                  />
                )}
              />
            </div>
            <div className={styles.row2}>
              <Field name="reason" component={InputBase as any} placeholder="Основание" className={styles.reason} />
              <div className={styles.checks}>
                <Field
                  name="is_smr"
                  render={({ input }) => (
                    <>
                      <Checkbox
                        checked={input.value}
                        onCheck={(e) => {
                          input.onChange(e.target.checked);
                        }}
                        labelPlacement={"right" as any}
                      >
                        СМР
                      </Checkbox>
                    </>
                  )}
                />
                <Field
                  name="is_mtr"
                  render={({ input }) => (
                    <>
                      <Checkbox
                        checked={input.value}
                        onCheck={(e) => {
                          input.onChange(e.target.checked);
                        }}
                        labelPlacement={"right" as any}
                      >
                        МТР
                      </Checkbox>
                    </>
                  )}
                />
              </div>
              {form.getFieldState("is_smr")?.value ? (
                <Dropdown
                  placeholder={(() => {
                    const enabledClarificationsCount = COEFFICIENT_CLARIFICATION_OPTIONS.reduce(
                      (enabledCount, option) => {
                        const currentOpitonState = form.getFieldState(option.id);
                        if (currentOpitonState?.value) {
                          enabledCount++;
                        }
                        return enabledCount;
                      },
                      0
                    );
                    if (!enabledClarificationsCount) return "Уточнение";
                    return `Уточнение (${enabledClarificationsCount})`;
                  })()}
                  classNameContainer={styles.clarification}
                  classNameContent={styles.clarificationOptions}
                  icon={<ArrowDownSelect />}
                  strategy="visibility"
                >
                  {COEFFICIENT_CLARIFICATION_OPTIONS.map((option) => (
                    <Field
                      name={option.id as string}
                      key={option.id}
                      render={({ input }) => (
                        <Checkbox
                          title={option.description}
                          checked={input.value}
                          onCheck={(e) => {
                            const checked = e.target.checked;
                            input.onChange(checked);
                          }}
                          labelPlacement={"right" as any}
                        >
                          {option.name}
                        </Checkbox>
                      )}
                    />
                  ))}
                </Dropdown>
              ) : null}
              <Field
                name="value"
                required
                render={({ input }) => (
                  <InputNumber
                    placeholder="Коэффициент"
                    value={input.value}
                    setValue={input.onChange}
                    decimalPlace={100}
                    hideControls
                    containerClassName={styles.coefficient}
                  />
                )}
              />
              <Actions
                canSave
                onSave={handleSubmit}
                color="blue"
                className={styles.actions}
                canRemove
                onRemoveDirectly={onCancel}
                deleteLabel="Отменить"
                disabled={isPending}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default HandlerCoefficientsForm;
