import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IOption } from "components/UI/atoms/Select";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import Modal from "shared/ui/modal/Modal";
import { COEFFICIENT_CLARIFICATION_OPTIONS } from "../../HandlerCoefficients";
import { handlerCoefficientsInvalidateKeySelector, handlerCoefficientsLoadingSelector } from "../../model/selectors";
import { createHandlerCoefficient, deleteHandlerCoefficient } from "../../model/thunks";
import HandlerCoefficientsForm from "../HandlerCoefficientsForm/HandlerCoefficientsForm";
import HandlerCoefficientsRow from "./HandlerCoefficientsRow";

import { IIdAndName } from "types/idAndName";
import { ICreateCoefficient, IHandlerCoefficient } from "../../model/types";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./HandlerCoefficientsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  section: IIdAndName;
  coefficients: IHandlerCoefficient[] | null;
  isLoading: boolean;
  defaultAmount: string;
  objectId: string;
}

const HandlerCoefficientsModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  section,
  coefficients,
  isLoading,
  defaultAmount,
  objectId
}) => {
  const dispatch = useDispatch();
  const invalidateKey = useSelector(handlerCoefficientsInvalidateKeySelector);
  const key = generateStorageKey({ type: "pending", buildingId: objectId });
  const isPending = useSelector(handlerCoefficientsLoadingSelector)[key];
  const isEmpty = !isLoading && !coefficients?.length;

  const createHandler = (vals: ICreateCoefficient) => {
    const validatedVals = Object.assign({}, vals);
    validatedVals.section = String(section.id);
    if (!validatedVals.is_smr) {
      COEFFICIENT_CLARIFICATION_OPTIONS.forEach((option) => {
        // @ts-ignore
        delete validatedVals[option.id];
      });
    }
    if (validatedVals.coefficient_step?.startsWith("coefficient_")) {
      validatedVals.applying_coefficient = Number(validatedVals.coefficient_step.split("_")[1]);
      // @ts-ignore
      delete validatedVals.coefficient_step;
    }
    dispatch(createHandlerCoefficient(objectId, validatedVals));
  };

  const [isAdding, setIsAdding] = useState(false);

  const onRemove = (indexId: number) => {
    dispatch(deleteHandlerCoefficient(objectId, indexId));
  };

  useEffect(() => {
    setIsAdding(false);
  }, [invalidateKey]);

  const coefficientsForSteps: IOption[] = useMemo(
    () =>
      coefficients?.map((cf) => ({
        id: `coefficient_${cf.id}`,
        name: `Коэффициент ${cf.number}`,
      })) || [],
    [coefficients]
  );

  const coefficientsById = useMemo(
    () =>
      coefficients?.reduce((acc, v) => {
        acc[v.id] = v;
        return acc;
      }, {} as Record<number, IHandlerCoefficient>) || {},
    [coefficients]
  );

  return (
    <Modal
      title="Примененные коэффициенты"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      closeOnClickOutside={false}
    >
      {!isEmpty && (
        <div className={styles.totalsRow}>
          <div className={styles.subtitle}>ЛСР: {section?.name}</div>
          <div className={styles.total}>
            <div>Исходная стоимость:</div>
            <span>{transformDigitToFinancial(defaultAmount, { withCurrencySign: true, withFloat: true })}</span>
          </div>
        </div>
      )}
      {!isEmpty && (
        <div className={cn(styles.tableHeader, styles.cols)}>
          <div>№</div>
          <div>Название</div>
          <div className={styles.indexHeader}>Коэффициент</div>
          <div className={styles.centered}>Применен к</div>
          <div className={styles.centered}>Уточнение</div>
          <div>Основание</div>
          <div>Применил</div>
          <div className={styles.cost}>Стоимость с учётом коэффициента</div>
        </div>
      )}
      <div className={styles.table}>
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner isStatic />
          </div>
        )}
        {coefficients?.map((el) => (
          <HandlerCoefficientsRow
            values={el}
            key={el.id}
            onRemove={onRemove}
            isPending={isPending}
            coefficientsForSteps={coefficientsForSteps}
            coefficientsById={coefficientsById}
            objectId={objectId}
          />
        ))}
        {isEmpty && <div className={styles.empty}>К ЛСР нет примененных коэффициентов</div>}
      </div>
      {isAdding && (
        <HandlerCoefficientsForm
          onSubmit={createHandler}
          onCancel={() => setIsAdding(false)}
          isPending={isPending}
          coefficientsForSteps={coefficientsForSteps}
        />
      )}
      <div className={styles.add}>
        {!isAdding && (
          <AddButton isWide text="Добавить коэффициент" textPosition="left" onClick={() => setIsAdding(true)} />
        )}
      </div>
    </Modal>
  );
};

export default HandlerCoefficientsModal;
