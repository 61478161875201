import { ESTIMATE_STATES_IDS } from "../constants";
import { PricingModes } from "features/MultiplePrices/utils/constants";

export const getSectionAmount = (section, activeEstimateStateId, activePricingMode) => {
  if (activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT) {
    return section?.indicators ? section.indicators.draft_estimate_amount : 0;
  }
  if (activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION && activePricingMode === PricingModes.view) {
    return section?.indicators ? section.indicators.estimate_amount_source : 0;
  }
  if (activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION) {
    return section?.indicators ? section.indicators.estimate_amount : 0;
  }
  return section?.indicators ? section.indicators.current_estimate_amount : 0;
};
