import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import { IOption } from "components/UI/atoms/Select";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import {
  handlerCoefficientsInvalidateKeySelector,
  handlerCoefficientsLoadingSelector,
  handlerCoefficientsSelector,
} from "./model/selectors";
import { getHandlerCoefficients } from "./model/thunks";
import HandlerCoefficientsModal from "./ui/HandlerCoefficientsModal/HandlerCoefficientsModal";
import HandlerCoefficientsPopover from "./ui/HandlerCoefficientsPopover/HandlerCoefficientsPopover";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { IIdAndName } from "types/idAndName";

import styles from "./HandlerCoefficients.module.scss";

export const COEFFICIENT_STEP_OPTIONS: IOption[] = [
  { id: "LSR", name: "ЛСР" },
  { id: "LSR_INDEX", name: "ЛСР + Индекс" },
];

export const COEFFICIENT_CLARIFICATION_OPTIONS: IOption[] = [
  { id: "is_ot", name: "ОТ", description: "Применяется только к оплате труда включенному в работу" },
  { id: "is_em", name: "ЭМ", description: "Применяется только к машинам и механизмам включенным в работу" },
  { id: "is_otm", name: "ОТМ", description: "Применяется только к оплате труда машинистов включенных в работу" },
  { id: "is_m", name: "М", description: "Применяется только к материалам включенным в работу" },
  { id: "is_nr", name: "НР", description: "Применяется только к накладным расходам" },
  { id: "is_sp", name: "СП", description: "Применяется только к сметной прибыли" },
];

interface IProps {
  section: IIdAndName & { indicators: { estimate_amount_source: string } };
  amounts: Record<string, string>;
  className?: string;
}

const HandlerCoefficients: React.FC<IProps> = ({ section, amounts, className }) => {
  const objectId = useObjectId(3);
  const dispatch = useDispatch();
  const coefficients = useSelector(handlerCoefficientsSelector)[section?.id!];
  const isLoading = useSelector(handlerCoefficientsLoadingSelector)[section?.id!];
  const invalidateKey = useSelector(handlerCoefficientsInvalidateKeySelector);

  const loadcoefficients = () => {
    dispatch(getHandlerCoefficients(objectId, section?.id!));
  };

  useEffect(() => {
    loadcoefficients();
  }, [invalidateKey, objectId, section?.id]);

  const openModal = () => {
    setIsModal(true);
  };

  const [isModal, setIsModal] = useState(false);

  const defaultAmount = section.indicators.estimate_amount_source;

  return (
    <div className={styles.container}>
      <PopoverOverlay
        content={
          <HandlerCoefficientsPopover
            defaultAmount={String(defaultAmount)}
            onClick={openModal}
            isLoading={isLoading}
            coefficients={coefficients}
          />
        }
        placement="right"
        popoverBorderColor="primary"
        openType="hover"
        className={styles.popoverTarget}
      >
        <ButtonBase primary onClick={openModal} className={cn(styles.buttonGroup, className)}>
          Коэффициенты
        </ButtonBase>
      </PopoverOverlay>
      <HandlerCoefficientsModal
        defaultAmount={String(defaultAmount)}
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        section={section}
        coefficients={coefficients}
        isLoading={isLoading}
        objectId={objectId}
      />
    </div>
  );
};

export default HandlerCoefficients;
