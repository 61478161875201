//@ts-nocheck
import { message } from "antd";
import axios from "axios";
import { Dispatch, compose } from "redux";
import { createSelector } from "reselect";

import { aggregationsActions } from "../../../../../pages/Handler/ui/Aggregations/model/slice";
import { loadAggregations } from "../../../../../pages/Handler/ui/Aggregations/model/thunks";
import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../../pages/Handler/ui/ProHandler/constants";
import { IReplacementInExpenditure } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/ReplacedExpenditure/ReplacedExpenditure";
import { IExpenditureInHandlerProduction } from "pages/Handler/ui/ProHandler/components/Body/components/Expenditures/types";

import {
  apiCreateExpenditure,
  apiLoadExpenditures,
  apiLoadSection,
  apiLoadSections,
  apiLoadTypeExpenditures,
  changeEstimateItemCostRequest,
  changeEstimateItemDiscountRequest,
  changeEstimateItemStatusRequest,
  changeEstimateItemsStateRequest,
  changeEstimateItemsStateToProductionRequest,
  changeExpenditureRequest,
  changeSectionRequest,
  deleteExpenditureRequest,
  getExpenditureSubMaterialsRequest,
  getExpendituresFueRequest,
  getReplacementExpenditures,
  replaceExpenditureDirectlyRequest,
  sectionsApi,
  swapExpendituresRequest,
  uploadReplacementFile,
} from "./sectionsApi";
import { RootState } from "app/store/rootReducer";

import { EXPENDITURE_TYPES } from "../../../../../constants/constant";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";
import { addOrReplaceItemInArray } from "./utils/addOrReplaceItemInArray";
import { changeArrayItemById } from "./utils/changeArrayItemById";
import { filterArrayItemById } from "./utils/filterArrayItemById";
import { filterSectionsWithoutExpenditures } from "./utils/filterSectionsWithoutExpenditures";
import { handlerOutOfEstimateUtils } from "./utils/handlerOutOfEstimateUtils";
import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { queryParamsFormatter } from "utils/formatters/queryParamsFormatter";

const moduleName = "sections";

const SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS = `${moduleName}/SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS`;
const SET_SECTIONS_WITH_NEW_CHILD_STATUS = `${moduleName}/SET_SECTIONS_WITH_NEW_CHILD_STATUS`;
const SET_SECTIONS = `${moduleName}/SET_SECTIONS`;

const CONFIRM_SECTION = `${moduleName}/CONFIRM_SECTION`;
const CANCEL_SECTION = `${moduleName}/CANCEL_SECTION`;
const DELETE_SECTION = `${moduleName}/DELETE_SECTION`;
const RESET_SECTIONS = `${moduleName}/RESET_SECTIONS`;
const CHANGE_SECTION = `${moduleName}/CHANGE_SECTION`;
const ADD_SECTION = `${moduleName}/ADD_SECTION`;

const SET_SECTION = `${moduleName}/SET_SECTION`;
const SET_SECTION_IS_LOADING = `${moduleName}/SET_SECTION_IS_LOADING`;
const RESET_SECTION = `${moduleName}/RESET_SECTION`;

const SET_EXPENDITURES = `${moduleName}/SET_EXPENDITURES`;
const SET_EXPENDITURES_INVALIDATE_KEY = `${moduleName}/SET_EXPENDITURES_INVALIDATE_KEY`;
const SET_EXPENDITURES_ARE_LOADING = `${moduleName}/SET_EXPENDITURES_ARE_LOADING`;
const RESET_EXPENDITURES = `${moduleName}/RESET_EXPENDITURES`;
const ADD_EXPENDITURE = `${moduleName}/ADD_EXPENDITURE`;
const ADD_EXPENDITURES_LIST = `${moduleName}/ADD_EXPENDITURES_LIST`;
const CHANGE_EXPENDITURE = `${moduleName}/CHANGE_EXPENDITURE`;
const CONFIRM_EXPENDITURE = `${moduleName}/CONFIRM_EXPENDITURE`;
const CANCEL_EXPENDITURE = `${moduleName}/CANCEL_EXPENDITURE`;
const DELETE_EXPENDITURE = `${moduleName}/DELETE_EXPENDITURE`;

const SET_EXPENDITURE_SUB_MATERIALS = `${moduleName}/SET_EXPENDITURE_SUB_MATERIALS`;
const SET_EXPENDITURES_SUB_MATERIALS_LOADING = `${moduleName}/SET_EXPENDITURES_SUB_MATERIALS_LOADING`;
const RESET_EXPENDITURES_SUB_MATERIALS = `${moduleName}/RESET_EXPENDITURES_SUB_MATERIALS`;

const ADD_EXPENDITURES_BY_SECTION = `${moduleName}/ADD_EXPENDITURES_BY_SECTION`;
const DROP_EXPENDITURES_BY_SECTIONS = `${moduleName}/DROP_EXPENDITURES_BY_SECTIONS`;
const SET_REPLACEMENT_OPTIONS_BY_EXPENDITURES = `${moduleName}/SET_REPLACEMENT_OPTIONS_BY_EXPENDITURES`;

const SET_SECTIONS_LOADING_BY_KEY = `${moduleName}/SET_SECTIONS_LOADING_BY_KEY`;

const SET_EXPENDITURES_FUE_DATA = `${moduleName}/SET_EXPENDITURES_FUE_DATA`;
const SET_IS_EXPENDITURES_FUE_LOADING = `${moduleName}/SET_IS_EXPENDITURES_FUE_LOADING`;
const SET_REPLACEMENT = `${moduleName}/SET_REPLACEMENT`;

const INVALIDATE_KEY = `${moduleName}/INVALIDATE_KEY`;

const SET_WORK_EDIT_PENDING = `${moduleName}/SET_WORK_EDIT_PENDING`;

export const INITIAL_STATE = {
  sectionsWithConfirmedChildStatus: null,
  sectionsWithNewChildStatus: null,
  sections: null,
  sectionsAreLoadingByKey: {},
  section: null,
  sectionIsLoading: true,
  expenditures: null,
  expendituresAreLoading: false,
  expendituresSubMaterials: {},
  expendituresSubMaterialsAreLoading: false,
  expendituresBySections: {},
  replacementOptionsByExpenditures: {},
  expendituresInvalidateKey: Math.random(),
  expendituresFue: {
    results: [],
    isLoading: true,
  },
  replacements: {},
  invalidateKey: 0,
  editWorksPendings: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SECTION:
      return { ...state, section: payload };
    case SET_SECTION_IS_LOADING:
      return { ...state, sectionIsLoading: payload };
    case RESET_SECTION:
      return { ...state, section: null };
    case RESET_SECTIONS:
      return {
        ...state,
        sectionsAreLoadingByKey: {},
        sectionsWithConfirmedChildStatus: null,
        sectionsWithNewChildStatus: null,
        sections: null,
      };
    case SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS:
      return { ...state, sectionsWithConfirmedChildStatus: payload };
    case SET_SECTIONS_WITH_NEW_CHILD_STATUS:
      return { ...state, sectionsWithNewChildStatus: payload };
    case SET_SECTIONS:
      return { ...state, sections: payload };
    case SET_EXPENDITURES:
      return { ...state, expenditures: payload };
    case SET_EXPENDITURES_INVALIDATE_KEY:
      return { ...state, expendituresInvalidateKey: payload };
    case SET_EXPENDITURES_ARE_LOADING:
      return { ...state, expendituresAreLoading: payload };
    case RESET_EXPENDITURES:
      return { ...state, expenditures: null };
    case ADD_EXPENDITURE:
      return {
        ...state,
        expenditures: { ...state.expenditures, results: [payload, ...state.expenditures.results] },
      };
    case ADD_EXPENDITURES_LIST:
      return {
        ...state,
        expenditures: { ...state.expenditures, results: [...payload, ...(state.expenditures.results || [])] },
      };
    case CHANGE_EXPENDITURE:
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          results: state.expenditures.results.map((expenditure) =>
            expenditure.id === payload.id ? { ...expenditure, ...payload } : expenditure
          ),
        },
      };
    case DELETE_EXPENDITURE:
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          results: state.expenditures.results.filter((expenditure) => expenditure.id !== payload.expenditureId),
        },
      };
    case CONFIRM_SECTION:
      return {
        ...state,
        sectionsWithNewChildStatus: filterArrayItemById(state.sectionsWithNewChildStatus, payload.id),
        sectionsWithConfirmedChildStatus: addOrReplaceItemInArray(state.sectionsWithConfirmedChildStatus, payload),
      };
    case CANCEL_SECTION:
      return {
        ...state,
        sectionsWithNewChildStatus: filterArrayItemById(state.sectionsWithNewChildStatus, payload.id),
      };
    case DELETE_SECTION:
      return {
        ...state,
        sections: filterArrayItemById(state.sections, payload.sectionId),
        sectionsWithNewChildStatus: filterArrayItemById(state.sectionsWithNewChildStatus, payload.sectionId),
        sectionsWithConfirmedChildStatus: filterArrayItemById(
          state.sectionsWithConfirmedChildStatus,
          payload.sectionId
        ),
      };
    case CHANGE_SECTION:
      return {
        ...state,
        sections: changeArrayItemById(state.sections, payload),
        sectionsWithConfirmedChildStatus: changeArrayItemById(state.sectionsWithConfirmedChildStatus, payload),
        sectionsWithNewChildStatus: changeArrayItemById(state.sectionsWithNewChildStatus, payload),
      };
    case ADD_SECTION:
      return { ...state, sections: [...state.sections, payload] };

    // можно утверждать или отклонять только со статусом "new"
    // поэтому в state.expenditures.results в данный момент лежат только expenditures со статусом "new"
    // утверждение/отклонение это смена статуса expenditure
    case CONFIRM_EXPENDITURE:
    case CANCEL_EXPENDITURE:
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          results: filterArrayItemById(state.expenditures.results, payload),
        },
      };
    case SET_EXPENDITURE_SUB_MATERIALS:
      return {
        ...state,
        expendituresSubMaterials: { ...state.expendituresSubMaterials, [payload.id]: payload.expenditures },
      };
    case SET_EXPENDITURES_SUB_MATERIALS_LOADING:
      return { ...state, expendituresSubMaterialsAreLoading: payload };
    case RESET_EXPENDITURES_SUB_MATERIALS:
      return { ...state, expendituresSubMaterials: {} };
    case ADD_EXPENDITURES_BY_SECTION:
      return {
        ...state,
        expendituresBySections: {
          ...state.expendituresBySections,
          [payload.sectionId]: payload.expenditures,
        },
      };
    case DROP_EXPENDITURES_BY_SECTIONS:
      return {
        ...state,
        expendituresBySections: {},
      };
    case SET_SECTIONS_LOADING_BY_KEY:
      return {
        ...state,
        sectionsAreLoadingByKey: {
          ...state.sectionsAreLoadingByKey,
          [payload.key]: payload.isLoading,
        },
      };
    case SET_EXPENDITURES_FUE_DATA:
      return {
        ...state,
        expendituresFue: {
          ...state.expendituresFue,
          results: payload,
        },
      };
    case SET_IS_EXPENDITURES_FUE_LOADING:
      return {
        ...state,
        expendituresFue: {
          ...state.expendituresFue,
          isLoading: payload,
        },
      };
    case SET_REPLACEMENT_OPTIONS_BY_EXPENDITURES:
      return {
        ...state,
        replacementOptionsByExpenditures: {
          ...state.replacementOptionsByExpenditures,
          [payload.key]: payload.options,
        },
      };
    case SET_REPLACEMENT:
      return {
        ...state,
        replacements: {
          ...state.replacements,
          [payload.replacementId]: payload.data as IReplacementInExpenditure,
        },
      };
    case INVALIDATE_KEY:
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case SET_WORK_EDIT_PENDING:
      return {
        ...state,
        editWorksPendings: {
          ...state.editWorksPendings,
          [payload.id]: payload.status,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const stateSelector = (state: RootState) => state[moduleName];

export const replacementsSelector = createSelector(
  stateSelector,
  (state) => state.replacements as Record<string, IReplacementInExpenditure>
);

export const sectionsWithConfirmedChildStatusSelector = createSelector(
  stateSelector,
  (state) => state.sectionsWithConfirmedChildStatus
);
export const sectionsWithNewChildStatusSelector = createSelector(
  stateSelector,
  (state) => state.sectionsWithNewChildStatus
);
export const sectionsSelector = createSelector(stateSelector, (state) => state.sections);
export const sectionsLoadingByKeySelector = createSelector(stateSelector, (state) => state.sectionsAreLoadingByKey);

export const sectionSelector = createSelector(stateSelector, (state) => state.section);
export const sectionIsLoadingSelector = createSelector(stateSelector, (state) => state.sectionIsLoading);

export const expendituresSelector = createSelector(stateSelector, (state) => state.expenditures);
export const expendituresAreLoadingSelector = createSelector(stateSelector, (state) => state.expendituresAreLoading);
export const expendituresSubMaterialsSelector = createSelector(
  stateSelector,
  (state) => state.expendituresSubMaterials
);
export const expendituresSubMaterialsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.expendituresSubMaterialsAreLoading
);

export const expendituresBySectionsSelector = createSelector(stateSelector, (state) => state.expendituresBySections);
export const expendituresInvalidateKeySelector = createSelector(
  stateSelector,
  (state) => state.expendituresInvalidateKey
);
export const replacementOptionsByExpendituresSelector = createSelector(
  stateSelector,
  (state) => state.replacementOptionsByExpenditures
);

export const expendituresFueDataSelector = createSelector(stateSelector, (state) => state.expendituresFue.results);
export const expendituresFueIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.expendituresFue.isLoading
);

export const handlerInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const handlerEditWorkPendingSelector = createSelector(stateSelector, (state) => state.editWorksPendings);

const setSectionsWithConfirmedChildStatusAction = (payload) => ({
  type: SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS,
  payload,
});
const setSectionsWithNewChildStatusAction = (payload) => ({ type: SET_SECTIONS_WITH_NEW_CHILD_STATUS, payload });
const setSectionsAction = (payload) => ({ type: SET_SECTIONS, payload });
export const resetSectionsAction = () => ({ type: RESET_SECTIONS });

const setSectionIsLoadingAction = (payload) => ({ type: SET_SECTION_IS_LOADING, payload });
const confirmSectionAction = (payload) => ({ type: CONFIRM_SECTION, payload });
const cancelSectionAction = (payload) => ({ type: CANCEL_SECTION, payload });
const deleteSectionAction = (payload) => ({ type: DELETE_SECTION, payload });
const changeSectionAction = (payload) => ({ type: CHANGE_SECTION, payload });
const addSectionAction = (payload) => ({ type: ADD_SECTION, payload });

const setExpendituresAction = (payload) => ({ type: SET_EXPENDITURES, payload });
const setExpendituresAreLoadingAction = (payload) => ({ type: SET_EXPENDITURES_ARE_LOADING, payload });
export const resetExpendituresAction = () => ({ type: RESET_EXPENDITURES });
const addExpenditureAction = (payload) => ({ type: ADD_EXPENDITURE, payload });
const addExpendituresListAction = (payload) => ({ type: ADD_EXPENDITURES_LIST, payload });
export const changeExpenditureAction = (payload) => ({ type: CHANGE_EXPENDITURE, payload });
const confirmExpenditureAction = (payload) => ({ type: CONFIRM_EXPENDITURE, payload });
const cancelExpenditureAction = (payload) => ({ type: CANCEL_EXPENDITURE, payload });
const deleteExpenditureAction = (payload) => ({ type: DELETE_EXPENDITURE, payload });

const invalidateExpendituresAction = () => ({ type: SET_EXPENDITURES_INVALIDATE_KEY, payload: Math.random() });

export const resetSectionAction = () => ({ type: RESET_SECTION });

export const dropExpendituresBySections = () => ({ type: DROP_EXPENDITURES_BY_SECTIONS });

export const startLoadingSection = (key) => ({ type: SET_SECTIONS_LOADING_BY_KEY, payload: { key, isLoading: true } });
export const stopLoadingSection = (key) => ({ type: SET_SECTIONS_LOADING_BY_KEY, payload: { key, isLoading: false } });
export const getSectionKey = (sectionId, buildingId) => `${sectionId}_${buildingId}`;

export const setExpenditureSubMaterialsAction = (payload) => ({ type: SET_EXPENDITURE_SUB_MATERIALS, payload });
export const setExpenditureSubMaterialsLoadingAction = (payload) => ({
  type: SET_EXPENDITURES_SUB_MATERIALS_LOADING,
  payload,
});
export const resetExpendituresSubMaterialsAction = () => ({ type: RESET_EXPENDITURES_SUB_MATERIALS });
/* @ts-ignore */
export const addExpendituresBySectionAction = (payload) => ({ type: ADD_EXPENDITURES_BY_SECTION, payload });
/* @ts-ignore */
export const setExpendituresFueData = (payload) => ({ type: SET_EXPENDITURES_FUE_DATA, payload }); /* @ts-ignore */
export const setIsExpendituresFueLoading = (isLoading) => ({
  type: SET_IS_EXPENDITURES_FUE_LOADING,
  payload: isLoading,
});

export const setReplacementOptionsByExpendituresAction = (key, options) => ({
  type: SET_REPLACEMENT_OPTIONS_BY_EXPENDITURES,
  payload: { key, options },
});

export const setReplacementOptionsByExpenditures = (buildingId, expenditureId) => async (dispatch) => {
  return getReplacementExpenditures(buildingId, expenditureId).then((responseData) =>
    dispatch(setReplacementOptionsByExpendituresAction(expenditureId, responseData?.data))
  );
};

export const loadSectionsWithConfirmedChildStatus =
  ({ buildingId, estimateStateId, parentId, chapterId }) =>
  async (dispatch) => {
    const requestParams = { with_child_status: ESTIMATE_ITEM_STATUSES.CONFIRMED };
    if (parentId) requestParams.parent = parentId;
    if (chapterId) requestParams.chapter = chapterId;

    return apiLoadSections(buildingId, estimateStateId, requestParams).then((responseData) =>
      compose(dispatch, setSectionsWithConfirmedChildStatusAction)(responseData.results)
    );
  };

export const loadSections =
  ({ buildingId, estimateStateId, parentId, chapterId }) =>
  async (dispatch) => {
    const requestParams = { parent: parentId };
    if (chapterId) requestParams.chapter = chapterId;

    compose(dispatch, startLoadingSection)(+buildingId);
    const response = await apiLoadSections(buildingId, estimateStateId, requestParams)
      .then((responseData) => compose(dispatch, setSectionsAction)(responseData?.results))
      .catch(errorCatcher);
    compose(dispatch, stopLoadingSection)(+buildingId);

    return response;
  };

export const loadSectionsWithNewChildStatus =
  ({ buildingId, estimateStateId, parentId, chapterId }) =>
  async (dispatch) => {
    const requestParams = { with_child_status: ESTIMATE_ITEM_STATUSES.NEW };
    if (chapterId) requestParams.chapter = chapterId;
    if (parentId) requestParams.parent = parentId;

    return apiLoadSections(buildingId, estimateStateId, requestParams).then((responseData) => {
      compose(dispatch, setSectionsWithNewChildStatusAction, filterSectionsWithoutExpenditures)(responseData.results);
    });
  };

export const loadSection = (buildingId, sectionId, estimateStateId) => async (dispatch) => {
  compose(dispatch, setSectionIsLoadingAction)(true);
  await apiLoadSection(buildingId, sectionId, estimateStateId).then((responseData) =>
    dispatch({ type: SET_SECTION, payload: responseData })
  );
  compose(dispatch, setSectionIsLoadingAction)(false);
};

export const getSectionWithoutLoading = (buildingId, sectionId, estimateStateId) => async (dispatch) => {
  await apiLoadSection(buildingId, sectionId, estimateStateId).then((responseData) =>
    dispatch({ type: SET_SECTION, payload: responseData })
  );
};

export const addSection = (buildingId, section) => (dispatch) => {
  return axios
    .post(`/building/${buildingId}/estimate/draft/sections/`, section)
    .then((response) => {
      compose(dispatch, addSectionAction)(response.data);
      message.success(`${section.parent ? "Подраздел" : "Раздел"} успешно создан`);
    })
    .catch(errorCatcher);
};

export const addOutOfEstimateSection =
  (buildingId: string, section, isWithoutNotification?: boolean) => (dispatch: Dispatch) => {
    return axios
      .post(`/building/${buildingId}/estimate/sections/default/new/`, section)
      .then((response) => {
        compose(dispatch, addSectionAction)(response.data);
        !isWithoutNotification && message.success(`${section.parent_id ? "Подраздел" : "Раздел"} успешно создан`);
      })
      .catch(errorCatcher);
  };

export const addOutOfEstimateExpenditure =
  (buildingId: number, expenditure: any, successCallback?: () => void) => (dispatch: Dispatch) => {
    return axios
      .post(`/building/${buildingId}/estimate/expenditures/default/new/`, expenditure)
      .then((response) => {
        compose(
          dispatch,
          addExpenditureAction
        )({
          ...response.data,
          is_default: response.data.is_default === undefined ? true : response.data.is_default,
          indicators: {
            count: response.data?.count,
            estimate_amount: response?.data?.estimate_amount,
            ...response.data?.indicators,
          },
        });
        message.success(`Расценка успешно создана`);
        successCallback?.();
      })
      .catch(errorCatcher);
  };

export const addOutOfEstimateExpendituresList = (buildingId: number, list: any) => (dispatch: Dispatch) => {
  axios
    .post(`/building/${buildingId}/estimate/expenditures/default/list/new/`, list)
    .then((response) => {
      const createdWork = response.data.find((e) => e.expenditure_type === EXPENDITURE_TYPES.WORK);
      dispatch(
        addExpendituresListAction(
          response.data.map((e) => ({
            ...e,
            is_default: e.is_default === undefined ? true : response.data.is_default,
            indicators: {
              count: e?.count,
              estimate_amount: e?.estimate_amount,
              ...e?.indicators,
            },
            ...(createdWork ? { related_work: createdWork } : {}),
          }))
        )
      );

      message.success(`Расценка успешно создана`);
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(invalidateExpendituresAction());
    });
};

export const changeSection =
  (buildingId, estimateStateId, { childStatus, ...changedSection }) =>
  (dispatch) => {
    return changeSectionRequest(buildingId, estimateStateId, changedSection)
      .then((response) => {
        message.success("Раздел успешно изменен");
        //compose(dispatch, changeSectionAction)(response.data);
      })
      .catch(errorCatcher);
  };

export const deleteSection = (buildingId, sectionId, estimateStateId) => (dispatch) => {
  return axios
    .delete(`/building/${buildingId}/estimate/${estimateStateId}/delete/${sectionId}/`)
    .then(() => {
      message.success(`Раздел успешно удален`);
      compose(dispatch, deleteSectionAction)({ sectionId });
    })
    .catch(errorCatcher);
};

export const loadExpenditures = (ids, expenditureStatus, isWithoutLoader) => async (dispatch) => {
  !isWithoutLoader && compose(dispatch, setExpendituresAreLoadingAction)(true);

  let expenditures;
  if (ids.estimateState === ESTIMATE_STATES_IDS.DRAFT) {
    expenditures = await apiLoadExpenditures(ids.building, ids.section);
  } else {
    expenditures = await apiLoadTypeExpenditures(ids.building, { section: ids.section }, ids.estimateState);
  }

  const estimateStateNotDraftAndProduction =
    [ESTIMATE_STATES_IDS.DRAFT, ESTIMATE_STATES_IDS.PRODUCTION].indexOf(ids.estimateState) === -1;

  if (expenditureStatus && estimateStateNotDraftAndProduction)
    expenditures = {
      ...expenditures,
      results: expenditures.results.filter((expenditure) => expenditure.status === expenditureStatus),
    };

  compose(dispatch, setExpendituresAction)(expenditures);
  compose(dispatch, setExpendituresAreLoadingAction)(false);
  expenditures?.results?.forEach((el) => {
    el.replacements?.forEach((repl) => {
      dispatch({
        type: SET_REPLACEMENT,
        payload: {
          data: repl,
          replacementId: repl.id,
        },
      });
    });
  });
};

export const changeEstimateItemDiscount = (buildingId, expenditureId, discount, callback) => (dispatch, getState) => {
  const sectionsInState = sectionsSelector(getState());
  return changeEstimateItemDiscountRequest(buildingId, expenditureId, discount).then(({ data }) => {
    if (callback) callback();
    const sectionCandidate = sectionsInState?.find((section) => section.id === expenditureId);
    if (!sectionCandidate) return;
    dispatch(
      changeSectionAction({
        ...sectionCandidate,
        indicators: { ...(sectionCandidate.indicators || {}), ...{ ...data, section: undefined } },
      })
    );
  });
};

export const changeEstimateItemCost = (buildingId, expenditureId, cost, callback, id, name) => (dispatch, getState) => {
  const sectionsInState = sectionsSelector(getState());
  return changeEstimateItemCostRequest(buildingId, expenditureId, cost, id, name).then(({ data }) => {
    if (callback) callback();
    const sectionCandidate = sectionsInState?.find((section) => section.id === expenditureId);
    if (!sectionCandidate) return;
    dispatch(
      changeSectionAction({
        ...sectionCandidate,
        indicators: { ...(sectionCandidate.indicators || {}), ...{ ...data, section: undefined } },
      })
    );
  });
};

export const createExpenditure = (buildingId, sectionId, expenditure, type) => async (dispatch) => {
  return apiCreateExpenditure(buildingId, sectionId, expenditure, type).then((responseData) => {
    return compose(dispatch, addExpenditureAction)(responseData);
  });
};

export const changeExpenditure = (ids, changedExpenditure, isOutOfEstimate, buildingId) => (dispatch) => {
  changeExpenditureRequest(ids, changedExpenditure, isOutOfEstimate)
    .then((response) => {
      compose(dispatch, changeExpenditureAction)(response.data);
      buildingId && dispatch(loadAggregations(buildingId));
      dispatch(aggregationsActions.invalidateKey());
      message.success("Позиция успешно изменена");
    })
    .catch(errorCatcher);
};

export const changeEstimateItemsState =
  (buildingId, { fromState, ...payload }) =>
  async () => {
    if (fromState === payload.state) {
      message.error("Выбранные позиции уже были перенесены");
      return;
    }

    if (payload.state === ESTIMATE_STATES_IDS.PRODUCTION) {
      return await changeEstimateItemsStateToProductionRequest(buildingId, { ids: payload.ids, state: fromState });
    } else {
      return await changeEstimateItemsStateRequest(buildingId, { ...payload, from_state: fromState });
    }
  };

export const confirmSection = (params, successCallback) => async (dispatch) => {
  compose(dispatch, startLoadingSection, getSectionKey)(params.section.id, params.buildingId);
  await changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.section.id],
    status: ESTIMATE_ITEM_STATUSES.CONFIRMED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, confirmSectionAction)({ ...params.section, status: ESTIMATE_ITEM_STATUSES.CONFIRMED });
      message.success("Раздел утвержден");
      successCallback?.();
    })
    .catch((e) => {
      errorCatcher(e);
    });
  compose(dispatch, stopLoadingSection, getSectionKey)(params.section.id, params.buildingId);
};

export const cancelSection = (params, successCallback) => async (dispatch: Dispatch) => {
  compose(dispatch, startLoadingSection, getSectionKey)(params.section.id, params.buildingId);
  await changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.section.id],
    status: ESTIMATE_ITEM_STATUSES.CANCELED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, cancelSectionAction)(params.section);
      dispatch(loadAggregations(params.buildingId) as any);
      message.success("Раздел отклонен");
      successCallback?.();
    })
    .catch((e) => {
      errorCatcher(e);
    });
  compose(dispatch, stopLoadingSection, getSectionKey)(params.section.id, params.buildingId);
};

export const confirmExpenditure = (params) => (dispatch: Dispatch) =>
  changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.expenditureId],
    status: ESTIMATE_ITEM_STATUSES.CONFIRMED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, confirmExpenditureAction)(params.expenditureId);
      message.success("Позиция утверждена");
    })
    .catch(errorCatcher);

export const cancelExpenditure = (params) => (dispatch: Dispatch) =>
  changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.expenditureId],
    status: ESTIMATE_ITEM_STATUSES.CANCELED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, cancelExpenditureAction)(params.expenditureId);
      message.success("Позиция отклонена");
    })
    .catch(errorCatcher);

export const deleteExpenditure = (ids) => async (dispatch: Dispatch) => {
  try {
    await deleteExpenditureRequest(ids);
    compose(dispatch, deleteExpenditureAction)({ expenditureId: ids.expenditure });
    message.success("Позиция успешно удалена");
  } catch (e: any) {
    errorCatcher(e);
  }
};

export const deleteDefaultItemInHandler =
  (objectId: string, id: number, successCallback?: () => void) => (dispatch: Dispatch) => {
    sectionsApi
      .deleteDefaultItem(objectId, id)
      .then(() => {
        message.success("Позиция успешно удалена");
        successCallback?.();
      })
      .catch(errorCatcher);
  };

export const getExpenditureSubMaterials = (buildingId, expenditureId) => (dispatch: Dispatch) => {
  compose(dispatch, setExpenditureSubMaterialsLoadingAction)(true);

  return getExpenditureSubMaterialsRequest(buildingId, expenditureId)
    .then((response) => {
      compose(dispatch, setExpenditureSubMaterialsAction)(response.data);
      compose(dispatch, setExpenditureSubMaterialsLoadingAction)(false);
    })
    .catch(errorCatcher);
};

export const getExpendituresBySection =
  (ids, filters = {}) =>
  (dispatch: Dispatch) => {
    const sectionIdFilter = { section: ids.section };
    const formattedfilters = queryParamsFormatter(filters, sectionIdFilter);
    return apiLoadTypeExpenditures(ids.building, formattedfilters, ids.estimateState, ids.section).then(
      (expenditures) => {
        expenditures?.results?.forEach((exp) => {
          if (exp.expenditure_type === EXPENDITURE_TYPES.WORK) {
            exp.related_resources = expenditures.results.filter(
              (resource) =>
                resource.expenditure_type &&
                resource.expenditure_type !== EXPENDITURE_TYPES.WORK &&
                resource.related_work?.id === exp.id
            );
          }
        });

        return compose(
          dispatch,
          addExpendituresBySectionAction
        )({
          expenditures,
          sectionId: ids.section,
        });
      }
    );
  };

export const getExpendituresFue = (buildingId: number, expenditureId: number) => (dispatch: Dispatch) => {
  dispatch(setIsExpendituresFueLoading(true));

  return getExpendituresFueRequest(buildingId, expenditureId)
    .then((payload) => {
      dispatch(setExpendituresFueData(payload.data.results));
    })
    .catch(errorCatcher)
    .finally(() => dispatch(setIsExpendituresFueLoading(false)));
};

export const swapExpenditures = (buildingId, old, candidate, successCallback) => (dispatch) => {
  return swapExpendituresRequest(buildingId, old, candidate, successCallback);
};

export const replaceExpenditureDirectly = (buildingId, expenditureId, replacementId, successCallback) => (dispatch) => {
  return replaceExpenditureDirectlyRequest(buildingId, expenditureId, replacementId, successCallback).then(() =>
    dispatch(invalidateExpendituresAction())
  );
};

export const loadReplacementFile =
  (buildingId: number, replacementId: number, file: File, type: string) => async (dispatch: Dispatch) => {
    const [serializedFile] = await getArrayFilesInBase64([file]);
    const data = {
      [`${type}_name`]: serializedFile.name,
      [`${type}_write`]: serializedFile.file,
    };
    uploadReplacementFile(data, buildingId, replacementId)
      .then(({ data }) => {
        dispatch({
          type: SET_REPLACEMENT,
          payload: { data, replacementId },
        });
      })
      .catch(errorCatcher);
  };

export const deleteReplacementFile =
  (buildingId: number, replacementId: number, type: string) => (dispatch: Dispatch) => {
    const data = {
      [`${type}_name`]: null,
      [`${type}_write`]: null,
    };
    uploadReplacementFile(data, buildingId, replacementId)
      .then(({ data }) => {
        dispatch({
          type: SET_REPLACEMENT,
          payload: { data, replacementId },
        });
      })
      .catch(errorCatcher);
  };

interface IEditExpendituresListProps {
  deletedMaterialsIds: number[];
  newMaterialIds: number[];
  values: IExpenditureInHandlerProduction["editingForm"];
  buildingId: number;
  sectionId: number;
  succesCallback: () => void;
}

export const editDefaultExpendituresList =
  ({
    deletedMaterialsIds,
    newMaterialIds,
    values,
    buildingId,
    sectionId,
    succesCallback,
  }: IEditExpendituresListProps) =>
  (dispatch: Dispatch) => {
    const relatedWorkId = values?.id!;
    const newMaterialsData = handlerOutOfEstimateUtils.extractNewResources(
      values,
      newMaterialIds,
      relatedWorkId,
      sectionId
    );
    const deleteReqs = deletedMaterialsIds.length
      ? [sectionsApi.deleteListDefaultItems(buildingId, deletedMaterialsIds)]
      : [];
    const editingExpenditures = handlerOutOfEstimateUtils.prepareValuesForEdit(
      values,
      newMaterialIds,
      deletedMaterialsIds
    );

    const editReqs = editingExpenditures?.map((el) => sectionsApi.editDefaultItemInList(buildingId, el)) ?? [];

    const addNewReqs = newMaterialsData.length
      ? [sectionsApi.addNewListDefaultItems(buildingId, newMaterialsData)]
      : [];

    dispatch({ type: SET_WORK_EDIT_PENDING, payload: { id: relatedWorkId, status: true } });
    Promise.allSettled([...editReqs, ...deleteReqs, ...addNewReqs])
      .then(() => {
        message.success("Сохранено");
        dispatch(invalidateExpendituresAction());
        succesCallback?.();
      })
      .finally(() => {
        dispatch({ type: SET_WORK_EDIT_PENDING, payload: { id: relatedWorkId, status: false } });
      });
  };

export const deleteDefaultWorkAndRelatedResourcesInHandler =
  (buildingId: number, workId: number, resourcesIds: number[] = []) =>
  async (dispatch: Dispatch) => {
    try {
      if (resourcesIds?.length) {
        await sectionsApi.deleteListDefaultItems(buildingId, resourcesIds);
      }
      await sectionsApi.deleteDefaultItem(String(buildingId), workId);
      dispatch(invalidateExpendituresAction());
    } catch (e: any) {
      errorCatcher(e);
    }
  };
