import React from "react";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";

import { IHandlerCoefficient } from "../../model/types";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./HandlerCoefficientsPopover.module.scss";

interface IProps {
  isLoading: boolean;
  onClick: React.MouseEventHandler;
  coefficients: IHandlerCoefficient[] | null;
  defaultAmount: string;
}

const HandlerCoefficientsPopover: React.FC<IProps> = ({ isLoading, onClick, coefficients, defaultAmount }) => {
  const isEmpty = !coefficients?.length;

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.header}>Коэффициенты</div>
      {isLoading && (
        <div className={styles.spinner}>
          <Spinner isSmall isStatic />
        </div>
      )}
      {!isLoading && isEmpty && (
        <div className={styles.empty}>Нет примененных на смету коэффициентов. Нажмите, чтобы добавить новый</div>
      )}
      <div className={styles.table}>
        {coefficients?.map((el) => (
          <div className={styles.row} key={el.id}>
            <div className={styles.name} title={el.name}>
              {el.name}
            </div>
            <div className={styles.count} title={el.value}>
              {el.value}
            </div>
          </div>
        ))}
      </div>

      {!isEmpty && (
        <div className={styles.footer}>
          <div>Без учета коэффициентов:</div>
          <div>{transformDigitToFinancial(defaultAmount)}</div>
        </div>
      )}
    </div>
  );
};

export default HandlerCoefficientsPopover;
