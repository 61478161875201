import { message } from "antd";
import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import Select, { IOption } from "components/UI/atoms/Select";

import { addPrice, deletePrice, editPrice } from "../model/thunks";
import { ReactComponent as CopyIcon } from "./../assets/copy.svg";
import { ReactComponent as DeleteIcon } from "./../assets/delete.svg";
import { ReactComponent as EditIcon } from "./../assets/edit.svg";
import { ReactComponent as PlusIcon } from "./../assets/plus.svg";
import { useConfirmModal } from "entities/ConfirmationModal/ConfirmationContext";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Actions from "shared/ui/controls/Actions/Actions";
import InputBase from "shared/ui/inputs/InputBase";

import { PricingModes } from "../utils/constants";

import { useGetMultiplePrices } from "../hooks/useGetMultiplePrices";

import { multiplePriceUtils } from "../utils/utils";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./MultiplePricesSelect.module.scss";

interface IProps {
  activePricingMode: any;
  setActivePricingMode: any;
  objectId: string;
}

const MultiplePricesSelect = ({ activePricingMode, setActivePricingMode, objectId }: IProps) => {
  const dispatch = useDispatch();

  const { multiplePricesLoadings, multiplePricesOptions } = useGetMultiplePrices(objectId);

  const onCopy = (name: string) => {
    dispatch(addPrice(objectId, multiplePriceUtils.getAvailableCopyName(multiplePricesOptions, name)));
  };

  const [isAdding, setIsAdding] = useState(false);
  const [newName, setNewName] = useState("");

  const onAdd = () => {
    if (!checkIsValid(newName)) return;
    dispatch(addPrice(objectId, newName));
    setIsAdding(false);
    setNewName("");
  };

  const [editingOption, setEditingOption] = useState<IOption | null>(null);

  const onEdit = () => {
    if (!editingOption) return;
    if (!checkIsValid(editingOption?.name as string)) return;
    dispatch(editPrice(objectId, editingOption.name as string, editingOption.id as number));
    setEditingOption(null);
  };

  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const onClickCC = (id: number) => {
    openConfirmModal({
      isOpen: true,
      onClose: closeConfirmModal,
      action: () => {
        dispatch(deletePrice(objectId, id));
        closeConfirmModal();
      },
      title: "Удалить цену?",
      acceptButtonText: "Удалить",
      className: styles.confirmModal,
      variant: "secondary",
    });
  };

  const onSelect = (priceId: any, priceName: any) => {
    setActivePricingMode(priceId);
  };

  const displayingValue = useMemo(() => {
    if ([PricingModes.budget, /* PricingModes.estimatedPrice, */ PricingModes.view].includes(activePricingMode)) {
      return activePricingMode;
    }
    const candidate = multiplePricesOptions?.find((el) => el.id === activePricingMode);
    if (candidate) return candidate.id;
    return PricingModes.view;
  }, [activePricingMode, multiplePricesOptions]);

  return (
    <Select
      className={styles.estimateStateSelect}
      onChange={onSelect}
      value={displayingValue}
      options={multiplePricesOptions}
      isLoading={multiplePricesLoadings[objectId]}
      endDecorator={
        isAdding && (
          <div className={styles.inputWrapper} onClick={stopEventPropagation}>
            <InputBase value={newName} onChange={({ target }) => setNewName(target.value)} />
            <Actions color="blue" canSave canRemove onRemoveDirectly={() => setIsAdding(false)} onSave={onAdd} />
          </div>
        )
      }
      renderOption={(option, onClick) => {
        const isLoading = multiplePricesLoadings[option.id];
        const isEditing = +option.id === editingOption?.id;

        if (isEditing) {
          return (
            <div className={styles.inputWrapper} onClick={stopEventPropagation}>
              <InputBase
                value={editingOption?.name as string}
                onChange={({ target }) =>
                  setEditingOption((prev) => ({ ...prev, name: target.value as string } as IOption))
                }
              />
              <Actions color="blue" canSave canRemove onRemoveDirectly={() => setEditingOption(null)} onSave={onEdit} />
            </div>
          );
        }

        const isDefault = [PricingModes.budget, PricingModes.view].includes(option.id as PricingModes);
        //@ts-expect-error
        const isDefaultEstimate = option.is_default; //=== PricingModes.estimatedPrice;

        return (
          <>
            <div
              className={cn(styles.option, activePricingMode === option.id && styles.activeOption)}
              onClick={() => onClick?.(option.id, option.name)}
            >
              <div className={styles.name}>{option.name}</div>
              {isLoading && <Spinner isSmallGray isStatic />}
              {!isLoading && (
                <div className={styles.actions} onClick={stopEventPropagation}>
                  {!isDefault && (
                    <CopyIcon
                      onClick={() => {
                        onCopy(option.name as string);
                      }}
                    />
                  )}
                  {!isDefault && !isAdding && <PlusIcon onClick={() => setIsAdding(true)} />}
                  {!isDefault && !isDefaultEstimate && <EditIcon onClick={() => setEditingOption(option)} />}
                  {!isDefault && !isDefaultEstimate && <DeleteIcon onClick={() => onClickCC(+option.id)} />}
                </div>
              )}
            </div>
          </>
        );
      }}
    />
  );
};

export default MultiplePricesSelect;

function checkIsValid(name?: string) {
  if (!name?.length) {
    message.error("Введите наименование");
    return false;
  }
  if (name.length > 15) {
    message.error("Наименование не должно превышать 15 символов");
    return false;
  }
  return true;
}
